// which files are added to the map
export const geojsonFiles = [
    "georef_sat",
    "georef_sat_error_vector",
    "georef_img",
    "georef_img_error_vector",
    "georef_calc",
    "georef_calc_error_vector",
    "tma_lines"
    //"tma_points"
]