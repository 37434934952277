// calculate centroid of a polygon and return lat, lng
function calc_centroid(geometry) {
  let geometryType = geometry.type;
  let coords = geometry.coordinates;
  let sumLat = 0,
    sumLng = 0;
  let numCoordinates = 0;

  if (geometryType === "MultiPolygon") {
    // Handle MultiPolygon geometry
    coords.forEach((polygonCoords) => {
      polygonCoords[0].forEach((coord) => {
        sumLng += coord[1]; // Longitude
        sumLat += coord[0]; // Latitude
        numCoordinates++;
      });
    });
  } else if (geometryType === "MultiLineString") {
    // Handle MultiLineString geometry
    coords.forEach((lineStringCoords) => {
      lineStringCoords.forEach((coord) => {
        sumLng += coord[1]; // Longitude
        sumLat += coord[0]; // Latitude
        numCoordinates++;
      });
    });
  }

  const centroid = [sumLat / numCoordinates, sumLng / numCoordinates];
  return [centroid[1], centroid[0]];
}

// convert an object (or a list of objects) to a csv file
function convert_data_to_csv(data) {
  // data is a dict with objects

  // create the header from the first object
  var csvStr = Object.keys(Object.values(data)[0]).join(';') + "\n";

  // iterate all objects in this data
  for (let obj of Object.values(data)){
    const csvData = Object.values(obj).join(';')
    
    csvStr = csvStr + csvData + "\n"
  }

  // Create a Blob with the CSV content
  const blob = new Blob([csvStr], { type: 'text/csv;charset=utf-8;' });

  return blob
}

// create an unique id
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

// determine icon type based on gisType
function get_icon_type(gisType) {
  let icon;
  switch (gisType) {
    case "point":
      icon = "fg-point";
      break;
    case "line":
      icon = "fg-polyline-pt";
      break;
    case "polygon":
      icon = "fg-polygon-pt";
      break;
    default:
      // Default to a generic icon if gisType is not recognized
      icon = "unknown";
  }
  return icon;
}

// Function to convert hex color to RGBA
function hex_to_RGBA(hex, opacity) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// join multipe dicts by key
function merge_dicts_by_key(dict1, dict2) {
  const merged = { ...dict1 };

  for (const [key, value] of Object.entries(dict2)) {
    if (merged[key]) {
      // Merge if the key exists in both dicts
      merged[key] = { ...merged[key], ...value };
    } else {
      // Otherwise, add the new key-value pair
      merged[key] = value;
    }
  }

  return merged;
}

// parse a csv file and return a dict defined by key
async function parse_csv(url, key_col) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const csvData = await response.text();

  return new Promise((resolve, reject) => {
    Papa.parse(csvData, {
      header: true,
      complete: results => {
        const dataDict = results.data.reduce((dict, row) => {
          const keyValue = row[key_col];
          if (keyValue) { // Make sure the key value is not undefined or empty
            dict[keyValue] = row;
          }
          return dict;
        }, {});

        resolve(dataDict);
      },
      error: error => reject(error)
    });
  });

}

// parse geojson and return a leaflet featureCollection
async function parse_geojson(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const geojson = await response.json();

  // The geojson object is already in the format of a GeoJSON FeatureCollection
  return geojson;

}

// parse a json file and return a dict
async function parse_json(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const json = await response.json();
  return json; // directly returning the parsed JSON object
}


// export the functions so that they can be used in the modules
export default {
  calc_centroid,
  convert_data_to_csv,
  create_UUID,
  get_icon_type,
  hex_to_RGBA,
  merge_dicts_by_key,
  parse_csv,
  parse_geojson,
  parse_json
};
