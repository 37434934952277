<template>
  <div>
    <!-- the leaflet map -->
    <div id="div_map"></div>

    <!-- the layer box -->
    <div id="div_layer_box" class="my-box">
      <!-- caption -->
      <h4 class="text-left border-bottom pb-1 mb-1">Layers</h4>
      <!-- content -->
      <div>
        <!-- iterate all layer groups -->
        <div
          v-for="(layersIds, groupName) in computed_layer_groups"
          :key="groupName"
        >
          <!-- title of layer group -->
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mt-2 mb-1">{{ groupName }}</h5>
            <i
              class="fa-regular fa-circle-question mt-2 mb-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="group_tooltips[groupName]"
            ></i>
          </div>
          <!-- Inner loop for items within each group -->
          <div
            v-for="layerId in layersIds"
            :key="layerId"
            class="d-flex justify-content-between"
          >
            <!-- smaller flex to keep elements to the left -->
            <div class="d-flex">
              <!-- input to select layer -->
              <input
                type="checkbox"
                class="me-2"
                @change="v_toggle_layer(layerId)"
                :checked="!status_layers[layerId].hidden"
              />
              <!-- layer name -->
              <div
                class="me-1"
                style="cursor: pointer"
                :class="{ 'text-muted': !status_layers[layerId].loaded }"
                @click="v_toggle_layer(layerId)"
              >
                {{ active_metadata[layerId].displayName }}
              </div>
              <!-- layer progress -->
              <div v-if="!status_layers[layerId].loaded">
                <i class="fas fa-spinner rotating"></i>
              </div>
            </div>
            <!-- layer color and type -->
            <div>
              <i
                :class="active_metadata[layerId].icon"
                :style="{ color: active_metadata[layerId].style.color }"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- the search box -->
    <div id="div_search_box" class="my-box">
      <!-- the search field itself -->
      <div class="input-group">
        <input
          type="search"
          class="form-control border-0"
          style="padding-right: 0px !important"
          placeholder="Search"
          aria-describedby="search-addon"
          v-model="search_text"
          :style="{
            color: search_entry_found ? 'black' : 'rgba(255, 0, 0, 0.7)',
          }"
          @focus="search_entry_found = true"
          @keyup="v_on_input_key_up"
        />
        <span
          class="input-group-text border-0"
          id="search-addon"
          style="cursor: pointer; margin-left: 0px"
          @click="v_jump_to_feature(search_text)"
        >
          <i class="fas fa-search"></i>
        </span>
      </div>
      <!-- the helper to select entries -->
      <div id="search_helper">
        <div
          v-for="obj of computed_search_helper"
          class="search_helper_entry"
          :key="obj.id"
          @click="v_jump_to_feature(obj.id, obj.layerId)"
        >
          <div>{{ obj.id }} {{ obj.layer_id }}</div>
        </div>
      </div>
    </div>

    <!-- the attribute box -->
    <div id="div_attribute_box" class="my-box d-flex flex-column">
      <!-- caption -->
      <h4 class="text-left border-bottom pb-2">Attributes</h4>
      <!-- content -->
      <div>
        <!-- if multiple feature are selected -->
        <div v-if="Object.keys(selected_features).length > 1">
          <div
            style="
              max-height: calc(100vh - 275px);
              overflow-y: auto;
              margin-right: -10px;
            "
          >
            <!-- iterate over all selected feature -->
            <div v-for="(obj, key) in selected_features" :key="key">
              {{ obj.feature_id }}
            </div>
          </div>
        </div>
        <!-- if one feature is selected -->
        <div v-else-if="Object.keys(selected_features).length == 1">
          <!-- Information for a feature -->
          <div>
            <!-- key title of feature -->
            <h5 class="text-center">
              {{ computed_feature_attributes.keyValue }}
            </h5>
            <!-- thumbnail of historical image -->
            <div v-if="computed_feature_attributes.thumbnail != null">
              <img
                class="img-fluid mx-auto d-block pb-2"
                style="height: 150px"
                :style="{
                  cursor:
                    computed_feature_attributes.imageLink !== null
                      ? 'pointer'
                      : 'default',
                }"
                :src="computed_feature_attributes.thumbnail"
                @click="v_download_image(computed_feature_attributes.imageLink)"
              />
            </div>
            <!-- tab navs -->
            <div v-if="computed_feature_attributes.attributeGroups.length > 1">
              <ul class="nav nav-tabs nav-fill mb-3" role="tablist">
                <li
                  v-for="(
                    attributeGroup, index
                  ) of computed_feature_attributes.attributeGroups"
                  :key="attributeGroup.id"
                  class="nav-item"
                  style="cursor: pointer"
                  role="presentation"
                >
                  <a
                    class="nav-link"
                    style="padding-left: 10px; padding-right: 10px"
                    data-mdb-tooltip-init
                    :title="attributeGroup.tooltip"
                    :class="{ active: index === selected_attribute_group_idx }"
                    @click="v_set_selected_attribute_group_idx(index)"
                    >{{ attributeGroup.title }}</a
                  >
                </li>
              </ul>
            </div>
            <!-- table with attributes -->
            <div
              v-if="computed_feature_attributes.attributeGroups.length > 0"
              class="overflow-auto"
              style="margin-right: -10px"
              :style="{ 'max-height': computed_feature_attributes.tableHeight }"
            >
              <div
                v-if="
                  computed_feature_attributes.attributeGroups[
                    this.selected_attribute_group_idx
                  ].attributes.length > 0
                "
              >
                <table class="table">
                  <tr
                    v-for="attributeObject in computed_feature_attributes
                      .attributeGroups[this.selected_attribute_group_idx]
                      .attributes"
                    :key="attributeObject.id"
                  >
                    <td>{{ attributeObject.title }}</td>
                    <td>{{ attributeObject.value }}</td>
                  </tr>
                </table>
              </div>
              <div v-else class="text-center text-muted mt-5">
                Data will be available soon.
              </div>
            </div>
            <!-- Information for no attributes -->
            <div
              v-if="computed_feature_attributes.attributeGroups.length == 0"
              class="text-center text-muted mt-5"
            >
              This feature has no attributes.
            </div>
          </div>
        </div>
        <!-- if no feature is selected -->
        <div v-else>
          <!-- Information for no selected feature -->
          <div class="text-center text-muted mt-5">
            Please select a feature to display it's attributes.
          </div>
        </div>
      </div>
      <!-- keep this always at the bottom -->
      <div class="mt-auto">
        <!-- Downloader -->
        <div v-if="Object.values(selected_features).length > 0" class="pb-1">
          <div style="font-size: 0.7em">
            Download the attributes
            <a
              class="text-primary"
              style="cursor: pointer"
              @click="v_download_data"
              >here</a
            >.
          </div>
        </div>
        <!-- data disclaimer -->
        <div id="data_disclaimer" class="border-top text-secondary">
          <div style="font-size: 0.7em">
            Geospatial support for this work provided by the
            <a href="https://www.pgc.umn.edu/">Polar Geospatial Center</a> under
            NSF-OPP awards 1043681, 1559691, and 2129685.
          </div>
        </div>
      </div>
    </div>

    <!-- image to switch base layers -->
    <div id="div_layer_switcher" @click="v_switch_basemap_layer">
      <img class="img-fluid" :src="computed_preview_base_layer" />
    </div>

    <!-- The icon to open the infobox -->
    <div id="div_infobox_icon" @click="v_toggle_info_modal">
      <i class="fa-solid fa-question"></i>
    </div>

    <!-- the infobox modal -->
    <div class="modal" id="infoboxModal" tab-index="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-between">
              <div>Welcome to the Polar Archive!</div>
              <i
                class="fa-solid fa-xmark"
                style="cursor: pointer"
                @click="v - v_toggle_info_modal()"
              ></i>
            </div>
            <div>
              This is an interactive web viewer to show the geo-referenced
              historical images of the TMA archive. This website is regulary
              updated with more geo-referenced and additional features, so make
              sure to come back!
            </div>
            <div class="border-top mt-2 pt-1 pb-2"></div>
            <div>
              <b>Click</b> - Select a feature and get it's attributes.<br />
              <b>Shift + Click</b> - Select/deselect additional features<br />
              <b>Shift + Drag</b> - Select multiple features at once<br />
            </div>
            <div class="border-top mt-2 pt-1 pb-2"></div>
            <div class="d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  class="me-2"
                  @change="v_toggle_info_cookie()"
                  :checked="active_hide_info"
                />
                <label @click="v_toggle_info_cookie()" data-toggle="tooltip" title="A cookie is used to save this setting"
                  ><u>Don't show this again</u></label
                >
              </div>
              <div
                class="text-end text-secondary text-decoration-underline smaller-text"
                style="cursor: pointer"
                @click="v_toggle_attribution_modal"
              >
                Attributions
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Attributions modal -->
    <div
      class="modal"
      id="attributionBoxModal"
      tab-index="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-between border-bottom mb-2">
              <div><b>Attributions</b></div>
              <i
                class="fa-solid fa-xmark"
                style="cursor: pointer"
                @click="v_toggle_attribution_modal()"
              ></i>
            </div>
            <div><b>Flaticon</b></div>
            <div>
              <a
                href="https://www.flaticon.com/free-icons/antarctica"
                title="antarctica icons"
                >Antarctica icons created by ChilliColor - Flaticon</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import functions
import snippets from "@/utils/snippets.js";

// import data
import { geojsonFiles } from "@/config/files.js";

// import OpenLayer
import GeoJSON from "ol/format/GeoJSON";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import proj4 from "proj4";
import Select from "ol/interaction/Select";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import Zoom from "ol/control/Zoom";

import { click } from "ol/events/condition";
import { DragBox } from "ol/interaction";
import { get } from "ol/proj";
import { getCenter } from "ol/extent";
import { register } from "ol/proj/proj4";
import { shiftKeyOnly } from "ol/events/condition";
import { Style, Stroke, Fill, Circle as CircleStyle } from "ol/style";

export default {
  name: "App",

  // data of the vue component
  data() {
    return {
      // loaded files and objects
      active_attributes: {}, // attributes of the feature in the layers
      active_base_layers: {}, // dict with the base layers
      active_data_files: [], // a list of loaded data files
      active_layers: {}, // active layers
      active_metadata: {}, // metadata of layers
      active_hide_info: false,

      // selected values
      drag_box: null,
      selected_features: {},
      selected_attribute_group_idx: 0, // the index of the selected attribute group
      selected_attribute_layer_group: null,

      // group information
      group_tooltips: {},

      // status of the layers
      status_layers: {},

      // map attributes
      map: null, // contains the leaflet map
      map_drag_box: null,
      map_extent: [-4194304, -4194304, 4194304, 4194304],
      map_min_zoom: 2,
      map_max_zoom: 16,
      map_search_zoom: 12,
      map_select: null, // the select element of OL
      map_start_zoom: 7,
      map_start_center: [-2000000, 1000000],

      // modal boxes
      modal_info: null,
      modal_attribution: null,

      // search attributes
      search_text: "",
      search_entry_found: true,
      max_search_entries: 5,
      min_search_length: 4,
    };
  },

  // happens after the website is mounted
  async mounted() {
    // define EPSG:3031 projection and register
    proj4.defs(
      "EPSG:3031",
      "+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs"
    );
    register(proj4);
    get("EPSG:3031").setExtent(this.map_extent);

    // load osm layer
    this.active_base_layers["osm"] = new TileLayer({
      source: new OSM(), // Using OpenStreetMap tile layer
    });

    // set source for MODIS layer
    var source = new WMTS({
      url: "https://gibs-{a-c}.earthdata.nasa.gov/wmts/epsg3031/best/wmts.cgi?TIME=2013-12-01",
      layer: "MODIS_Terra_CorrectedReflectance_TrueColor",
      format: "image/jpeg",
      matrixSet: "250m",

      tileGrid: new WMTSTileGrid({
        origin: [-4194304, 4194304],
        resolutions: [8192.0, 4096.0, 2048.0, 1024.0, 512.0, 256.0],
        matrixIds: [0, 1, 2, 3, 4, 5],
        tileSize: 512,
      }),
    });

    // load MODIS layer and set invisible
    this.active_base_layers["modis"] = new TileLayer({
      source: source,
      extent: [-4194304, -4194304, 4194304, 4194304],
    });
    this.active_base_layers["modis"].setVisible(false);

    // create the OpenLayerMap map
    this.map = new Map({
      target: "div_map", // the id of the div where the map will be rendered,
      layers: Object.values(this.active_base_layers),
      view: new View({
        projection: get("EPSG:3031"),
        extent: this.map_extent,
        center: this.map_start_center, // Center of the map
        zoom: this.map_start_zoom, // Initial zoom level
        minZoom: this.map_min_zoom,
        maxZoom: this.map_max_zoom,
      }),
      controls: [], // remove all controls
    });

    // create zoom control
    const zoomControl = new Zoom({
      className: "ol-zoom", // default CSS class, can be overridden for custom styling
      target: null, // By default, control will be added to the map's control container
      zoomInLabel: "+", // Label for zoom in, can be text or an element
      zoomOutLabel: "", // Label for zoom out set via css
    });

    // add zoom again
    this.map.addControl(zoomControl);

    // create Select element
    this.map_select = new Select({});

    // add listener to map
    this.map.addInteraction(this.map_select);

    // define what happens when click is happening
    this.map_select.on("select", (event) => {
      // check if shift key is pressed
      const shiftKeyPressed = event.mapBrowserEvent.originalEvent.shiftKey;

      // check if we selected an object
      if (event.selected.length > 0) {
        // get the feature and properties
        const feature = event.selected[0];
        const properties = feature.getProperties();

        // get the feature id (it's always the second value) & layer id (it's always layer_id)
        let selected_feature_id = Object.values(properties)[1];
        let selected_layer_id = feature.get("layer_id");

        // merge to one id
        let merged_id = selected_feature_id + selected_layer_id;

        if (shiftKeyPressed) {
          // Shift click: toggle the feature in selected_features
          if (this.selected_features.hasOwnProperty(merged_id)) {
            // Key found in the object, remove it
            delete this.selected_features[merged_id];
          } else {
            // Key not found, add it with the given value
            this.selected_features[merged_id] = {
              feature_id: selected_feature_id,
              layer_id: selected_layer_id,
            };
          }
        } else {
          // Normal click: replace selected_features with just the selected object
          this.selected_features = {
            [merged_id]: {
              feature_id: selected_feature_id,
              layer_id: selected_layer_id,
            },
          };
        }
      } else {
        if (!shiftKeyPressed) {
          // reset the selected fields
          this.selected_features = {};
        }
        // If shift key is pressed and clicked on no feature, do nothing
      }
    });

    // Create a DragBox interaction
    this.map_drag_box = new DragBox({
      condition: shiftKeyOnly,
      style: new Style({
        stroke: new Stroke({
          color: [0, 0, 255, 1],
        }),
      }),
    });

    // Add the DragBox interaction to the map
    this.map.addInteraction(this.map_drag_box);

    // Event listener for the end of the dragging
    this.map_drag_box.on("boxend", () => {
      // get extent of box
      const box_extent = this.map_drag_box.getGeometry().getExtent();

      // reset the selected features
      this.selected_features = {};

      // and also in open layer
      this.map_select.getFeatures().clear();

      // we need to mape "this" also available in the sub function
      let super_this = this;

      // Iterate over each active layer
      for (const layerId in this.active_layers) {
        const layer = this.active_layers[layerId];

        // Check if layer is a VectorLayer
        if (layer instanceof VectorLayer) {
          // Get the source of the layer
          const source = layer.getSource();

          // Collect features that intersect the box extent
          source.forEachFeatureIntersectingExtent(
            box_extent,
            function (feature) {
              const properties = feature.getProperties();

              // selected in open layers
              super_this.map_select.getFeatures().push(feature);

              // get the feature id (it's always the second value) & layer id (it's always layer_id)
              let selected_feature_id = Object.values(properties)[1];
              let selected_layer_id = feature.get("layer_id");

              let merged_id = selected_feature_id + selected_layer_id;

              super_this.selected_features[merged_id] = {
                feature_id: selected_feature_id,
                layer_id: selected_layer_id,
              };
            }
          );
        }
      }
    });

    // create the modals
    var infoModalElement = document.getElementById("infoboxModal");
    this.modal_info = new mdb.Modal(infoModalElement);
    var attrModalElement = document.getElementById("attributionBoxModal");
    this.modal_attribution = new mdb.Modal(attrModalElement);

    // check if we need to show the infobox
    if (this.v_get_cookie("hide_info_modal") != "true") {
      this.v_toggle_info_modal();
      this.active_hide_info = false;
    } else {
      this.active_hide_info = true;
    }

    // load the tooltips for the groups
    this.group_tooltips = await snippets.parse_json("/data/layer_groups.json");

    // first iterate all metadadata files, save them and create status for the file
    for (const fileName of geojsonFiles) {
      // create id for this file
      const fileId = snippets.create_UUID();

      // create urls
      const metadataUrl = "/data/geom/" + fileName + ".json";

      // parse metadatafile
      const metaDataDict = await snippets.parse_json(metadataUrl);

      // add some internal attributes
      metaDataDict.icon = snippets.get_icon_type(metaDataDict.gisType);

      // save the metadata
      this.active_metadata[fileId] = metaDataDict;

      // create statusDict
      const statusDict = {
        loaded: false,
        hidden: false,
      };

      // save statusDict
      this.status_layers[fileId] = statusDict;
    }

    // now iterate again and load the content (geojson and attributes)
    for (const fileName of geojsonFiles) {
      const geojsonUrl = "/data/geom/" + fileName + ".geojson";

      // get the id of this file
      const fileId = Object.keys(this.active_metadata).find(
        (key) => this.active_metadata[key].fileName === fileName + ".geojson"
      );

      // get metadata again
      const metaDataDict = this.active_metadata[fileId];

      // parse geojson file
      const featureCollection = await snippets.parse_geojson(geojsonUrl);

      // create dataDict
      let attributeDict = {};

      // check for possible data files
      for (let csvObj of metaDataDict.attributeFiles) {
        // define url for csv
        const csvUrl = "/data/attributes/" + csvObj.fileName;

        // check if we file is already loaded
        if (this.active_data_files.includes(csvUrl)) {
          continue;
        }

        // load data
        let csvData = await snippets.parse_csv(csvUrl, csvObj.fileKey);

        // if we don't have data yet just save
        if (Object.keys(attributeDict).length == 0) {
          attributeDict = csvData;
        } else {
          attributeDict = snippets.merge_dicts_by_key(attributeDict, csvData);
        }

        // add to the global data
        this.active_attributes[metaDataDict.keyField] = attributeDict;

        // save the url in the loaded files (so that we don't load it multiple times)
        this.active_data_files.push(csvUrl);
      }
      // add to the map
      this.v_add_to_map(fileId, featureCollection, metaDataDict.style);
    }
  },

  // computed data of the vue component
  computed: {
    // get all attributes for a feature
    computed_feature_attributes() {
      // if selected features are empty or too many, we don't need to compute furthe
      if (Object.keys(this.selected_features).length != 1) {
        return {};
      }

      // get feature and layer id
      var selected_feature_id = Object.values(this.selected_features)[0]
        .feature_id;
      var selected_layer_id = Object.values(this.selected_features)[0].layer_id;

      // get the metadata and key field
      let metadata = this.active_metadata[selected_layer_id];
      let featureKeyField = metadata.keyField;

      // create empty featuresAttributes
      const feature_attributes = {
        keyValue: selected_feature_id,
        thumbnail: null,
        imageLink: null,
        tableHeight: null,
        attributeGroups: [],
      };

      // check if we have attributes for this particular layer
      if (this.active_attributes[featureKeyField] == undefined) {
        return feature_attributes;
      }

      // get the attributes for this feature
      let attributes =
        this.active_attributes[featureKeyField][selected_feature_id];

      // check if we have attributes for this particular feature
      if (attributes == undefined) {
        return feature_attributes;
      }

      // check if we can use the same group ix
      if (this.selected_attribute_layer_group != metadata.layerGroup) {
        this.selected_attribute_group_idx = 0;
      }

      // set the right layerGroup
      this.selected_attribute_layer_group = metadata.layerGroup;

      // get the information from the metadata
      let attributeGroups = JSON.parse(
        JSON.stringify(metadata.attributeGroups)
      );
      let attributeColumns = JSON.parse(
        JSON.stringify(metadata.attributeColumns)
      );

      // iterate all groups of the attributes to fill the attribute groups with data
      for (let group of attributeGroups) {
        // get all attributes objects of this group
        const attrObjects = attributeColumns
          .filter((item) => item.group === group.id)
          .sort((a, b) => a.order - b.order); // Sorting by order

        // add the value there
        for (let attrObj of attrObjects) {
          attrObj.value = attributes[attrObj.id];

          // if decimals are specified, round the value
          if (attrObj["decimals"] != undefined) {
            let num = parseFloat(attrObj.value);

            // only round if we really have a number
            if (!isNaN(num)) {
              attrObj.value = parseFloat(attrObj.value).toFixed(
                attrObj["decimals"]
              );
            }
          }

          // add unit to value if necessary
          if (
            attrObj["unit"] != undefined &&
            attrObj.value != undefined &&
            attrObj.value.length > 0
          ) {
            attrObj.value = attrObj.value + attrObj["unit"];
          }
        }

        // save to the group
        group.attributes = attrObjects;
      }

      // Sort the attributeGroups by their order property
      attributeGroups.sort((a, b) => a.order - b.order);

      // calculate the what is left over for the table / what we substract from 100% height
      // (padding - caption - title - disclaimer)
      let tableDiff = 20 + 45.438 + 32 + 71.688 + 30 + 115;

      // if we have the navbar, the table get's even smaller
      if (attributeGroups.length > 1) {
        tableDiff = tableDiff + 56;
      }

      // check if we can add a thumbnail
      let featureThumbnail = metadata.thumbnail;
      if (featureThumbnail.length > 0) {
        feature_attributes.thumbnail = featureThumbnail.replace(
          /\{(\w+)\}/g,
          (match, key) => attributes[key] || match
        );

        // the image reduces the size for the table even more
        tableDiff = tableDiff + 150;
      } else {
        feature_attributes.thumbnail = null;
      }

      //add image link
      let imageLink = metadata.imageLink;
      if (imageLink.length > 0) {
        feature_attributes.imageLink = imageLink.replace(
          /\{(\w+)\}/g,
          (match, key) => attributes[key] || match
        );
      } else {
        feature_attributes.imageLink = null;
      }

      // save attribute Groups
      feature_attributes.attributeGroups = attributeGroups;

      // save table height
      feature_attributes.tableHeight = `calc(100vh - ${tableDiff}px)`;

      return feature_attributes;
    },

    // get all unique groups from the metadata
    computed_layer_groups() {
      // no need to do anything with empty objects
      if (Object.keys(this.active_metadata).length == 0) {
        return {};
      }

      // Initialize an empty object to store the groups
      const groups = {};

      // Loop through the objects
      for (const key in this.active_metadata) {
        if (this.active_metadata.hasOwnProperty(key)) {
          const obj = this.active_metadata[key];
          const groupKey = obj["layerGroup"];

          // Initialize the array if it doesn't exist
          if (!groups[groupKey]) {
            groups[groupKey] = [];
          }

          // Add the current object's ID to the corresponding group
          groups[groupKey].push(key); // Assuming 'key' is the ID of the object
        }
      }

      return groups;
    },

    // compute a search helper
    computed_search_helper() {
      // Check if the search text is long enough
      if (this.search_text.length < this.min_search_length) {
        return [];
      }

      // make search text lower case
      let search_lower = this.search_text.toLowerCase();

      let searchResults = [];

      // Iterate over all active layers
      for (const layerId in this.active_layers) {
        // check if we even need to search this layer
        if (this.active_metadata[layerId].searchable == false) {
          continue;
        }

        // get the layer by id
        const layer = this.active_layers[layerId];

        // Get the source of the layer
        const source = layer.getSource();
        if (source instanceof VectorSource) {
          // Iterate over each feature in the source
          source.forEachFeature((feature) => {
            const featureId = Object.values(feature.getProperties())[1];

            let featureId_lower = String(featureId).toLowerCase();

            // Convert featureId to string and check if it contains the search text
            if (featureId_lower.includes(search_lower)) {
              searchResults.push({ id: featureId, layerId: layerId });
            }
          });
        }
      }

      // Limit the number of search results
      return searchResults.slice(0, this.max_search_entries);
    },

    // compute the path to the preview image for base layer switching
    computed_preview_base_layer() {
      if (Object.keys(this.active_base_layers).length == 0) {
        return "";
      }

      let img_src;
      if (this.active_base_layers["osm"].getVisible()) {
        img_src = "/img/antarctica_sat.png";
      } else {
        img_src = "/img/antarctica_osm.png";
      }
      return img_src;
    },
  },

  // methods of this component
  methods: {
    // add a layer (equals objects) to the map
    v_add_to_map(id, featureCollection, style) {
      // Define the color and opacity
      const fillColor = style["color"] || "#3388ff"; // Default fill color
      const fillOpacity = style["opacity"] || 1; // Default opacity

      // Convert hex color to RGBA
      const rgbaFillColor = snippets.hex_to_RGBA(fillColor, fillOpacity);

      // Define a style for the features
      const featuresStyle = function (feature) {
        let styleConfig = {
          stroke: new Stroke({
            color: style["color"] || "#3388ff", // Default color
            width: style["weight"] || 2, // Line width
          }),
          fill: new Fill({
            color: rgbaFillColor,
          }),
        };

        if (feature.getGeometry().getType() === "Point") {
          styleConfig.image = new CircleStyle({
            radius: 2,
            fill: new Fill({
              color: style["color"],
            }),
          });
        }

        return new Style(styleConfig);
      };

      // Create features from the GeoJSON
      const features = new GeoJSON().readFeatures(featureCollection, {
        dataProjection: "EPSG:4326", // Assuming the data is in WGS84
        featureProjection: this.map.getView().getProjection(), // Transform to map projection
      });

      // Set a custom property 'layerId' for each feature
      features.forEach((feature) => {
        feature.set("layer_id", id, /* opt_silent */ true);
      });

      // Create a source for the vector layer
      const vectorSource = new VectorSource({
        features: features, // Add the features to the source
      });

      // Create the vector layer with the source and style
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: featuresStyle,
      });

      // Add the layer to the map
      this.map.addLayer(vectorLayer);

      // Save the layer for later use
      this.active_layers[id] = vectorLayer;

      // Set layer as loaded
      this.status_layers[id].loaded = true;
    },

    // download the selected features
    v_download_data() {
      // final check if we really have data selected
      if (Object.keys(this.selected_features).length == 0) {
        return;
      }

      let attributes_selected_features = {};

      // iterate all selected objects
      for (let [id, obj] of Object.entries(this.selected_features)) {
        // get feature and layer id
        let selected_feature_id = obj.feature_id;
        let selected_layer_id = obj.layer_id;

        // get the metadata and key field
        let metadata = this.active_metadata[selected_layer_id];
        let featureKeyField = metadata.keyField;

        // check if we have attributes for this particular layer
        if (this.active_attributes[featureKeyField] == undefined) {
          continue;
        }

        // get the attributes for this feature
        let attributes =
          this.active_attributes[featureKeyField][selected_feature_id];

        // check if we have attributes for this particular feature
        if (attributes == undefined) {
          continue;
        }

        // save in the dict
        attributes_selected_features[id] = attributes;
      }

      // convert the data to a csv file
      let csv_data = snippets.convert_data_to_csv(attributes_selected_features);

      // set file name (very creativly)
      let file_name = "data";

      // if only one feature is selected, the file can have a custom name
      if (Object.keys(this.selected_features).length == 1) {
        file_name = Object.values(this.selected_features)[0].feature_id;
      }

      // Create a link and set the URL using the Blob
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(csv_data);
        link.setAttribute("href", url);
        link.setAttribute("download", file_name);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    // download the image
    v_download_image(imageLink) {
      // nothing should happen if there's no imageLink
      if (imageLink == null) {
        return;
      }

      window.open(imageLink, "_blank");
    },

    v_get_cookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    // jump to a feature
    v_jump_to_feature(feature_id, layer_id) {
      if (this.search_text.length == 0) {
        return;
      }

      // convert featureid to string and lowercase
      feature_id = String(feature_id).toLowerCase();

      // if we don't have a layer id we need to consider all layers
      var layers_to_search = [];
      if (layer_id == undefined) {
        for (var loop_layer_id of Object.keys(this.active_layers)) {
          if (this.active_metadata[loop_layer_id].searchable == false) {
            continue;
          }
          layers_to_search.push(this.active_layers[loop_layer_id]);
        }
        // otherwise we can limit ourself to one layer
      } else {
        var layers_to_search = [this.active_layers[layer_id]];
      }

      let foundFeature = null;

      // Iterate over all layers to search
      for (const layer of layers_to_search) {
        // Find the feature with the specified ID in the layer's source
        layer.getSource().forEachFeature((feature) => {
          if (
            String(Object.values(feature.getProperties())[1]).toLowerCase() ===
            feature_id
          ) {
            foundFeature = feature;
          }
        });

        // Break the loop if we found the feature
        if (foundFeature) break;
      }

      // If a feature is found, pan to it
      if (foundFeature) {
        let featureCoordinates;

        // Determine the feature's location
        const featureGeometry = foundFeature.getGeometry();
        if (featureGeometry.getType() === "Point") {
          featureCoordinates = featureGeometry.getCoordinates();
        } else {
          const extent = featureGeometry.getExtent();
          featureCoordinates = getCenter(extent);
        }
        // Pan to the feature
        this.map.getView().animate({
          center: featureCoordinates,
          zoom: this.map_search_zoom,
        });

        // Select this feature
        this.selected_feature_id = feature_id;
        this.selected_layer_id = layer_id;

        // Delete the search text
        this.search_text = "";

        // Optionally, remove focus from the search element
        document.getElementById("div_map").focus();
      } else {
        this.search_entry_found = false;
      }
    },

    v_on_input_key_up(event) {
      if (event.key == "Enter") {
        this.v_jump_to_feature(this.search_text);
      } else {
        this.search_entry_found = true;
      }
    },

    v_set_cookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },

    // set the selected index for attribute group
    v_set_selected_attribute_group_idx(index) {
      this.selected_attribute_group_idx = index;
    },

    // seat searchtext for features
    v_set_search_text(searchText) {
      this.search_text = searchText;
    },

    // show the attribution modal
    v_toggle_attribution_modal() {
      // show or hide the modal
      if (this.modal_attribution._isShown) {
        this.modal_attribution.hide();
      } else {
        this.modal_info.hide();
        this.modal_attribution.show();
      }
    },

    // switch the basemap layers
    v_switch_basemap_layer() {
      if (this.active_base_layers["osm"].getVisible()) {
        this.active_base_layers["osm"].setVisible(false);
        this.active_base_layers["modis"].setVisible(true);
      } else {
        this.active_base_layers["osm"].setVisible(true);
        this.active_base_layers["modis"].setVisible(false);
      }
    },

    v_toggle_info_cookie() {
      if (this.v_get_cookie("hide_info_modal") !== "true") {
        // Set the cookie to hide the infobox for 365 days
        this.v_set_cookie("hide_info_modal", "true", 365);
        this.active_hide_info = true;
      } else {
        this.v_set_cookie("hide_info_modal", "false", 365);
        this.active_hide_info = false;
      }
    },

    // show & hide the info modal
    v_toggle_info_modal() {
      // show or hide the modal
      if (this.modal_info._isShown) {
        this.modal_info.hide();
      } else {
        this.modal_info.show();
      }
    },

    // show & hide an layer
    v_toggle_layer(id) {
      // Get the current visibility status of the layer
      const isVisible = this.active_layers[id].getVisible();

      // Toggle the visibility
      this.active_layers[id].setVisible(!isVisible);

      // toggle layer status
      this.status_layers[id].hidden = !this.status_layers[id].hidden;
    },
  },
};
</script>

<style>
#div_map {
  height: 100vh;
  width: 100vw;
}

.my-box {
  position: absolute;
  background: white;
  padding: 10px;
  z-index: 1000;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

#div_layer_box {
  top: 10px;
  left: 10px;
  width: 200px;
  max-height: calc(100vh - 35px);
}

#div_search_box {
  top: 10px;
  height: 39.27px;
  width: 250px;
  left: calc(50% - 125px);
  padding: 0px;
}

#div_attribute_box {
  top: 10px;
  right: 10px;
  width: 250px;
  height: calc(100vh - 125px);
}

/* for the search helper */
#search_helper {
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-top: none;
}

.search_helper_entry {
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px solid lightgray;
  cursor: pointer;
}

.search_helper_entry:hover {
  color: #3b71ca;
}

/* for the base layer switcher */
#div_layer_switcher {
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 70px;
  width: 72px;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  cursor: pointer;
}

#div_layer_switcher img {
  border-radius: 8px;
}

/* for the info box */
#div_infobox_icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 38px;
  height: 38px;
  width: 36px;
  bottom: 56px;
  right: 10px;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  cursor: pointer;
}

/* for the dragbox */
.ol-dragbox {
  border-color: red;
  border-width: 3px;
  background-color: rgba(255, 255, 255, 0.4);
}

/* for the zoom button */
.ol-zoom {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.ol-zoom > * {
  border: None;
  font-size: 20px;
  width: 34px;
}

.ol-zoom-out {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.ol-zoom-out::before {
  content: "−";
  /* U+2212 minus sign */
}
</style>
